/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import {
  Container, Row, Col, Media, Button
} from 'reactstrap';
import { graphql } from 'gatsby';
import { Field, Form, Formik } from 'formik';
import Img from 'gatsby-image';
import axios from 'axios';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import homeIcon from '../assets/images/contact_home.png';
import mailIcon from '../assets/images/mail.png';
import { Layout, FormikInput, FormikSelect } from '../Components';

const ContactForm = () => {
  const [formResponse, setFormResponse] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  return !formResponse ? (
    <Formik
      initialValues={{
        email: '',
        firstname: '',
        surname: '',
        phone: '',
        org: '',
        message: '',
        subject: 'Question sur le sous-programme Culture'
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.string().required('Ce champs est requis'),
        message: Yup.string().required('Ce champs est requis'),
        firstname: Yup.string()
          .max(50, 'La valeur du champs est trop longue')
          .required('Ce champs est requis'),
        surname: Yup.string()
          .max(50, 'La valeur du champs est trop longue')
          .required('Ce champs est requis'),
        email: Yup.string().email('Adresse email incorrecte').required('Ce champs est requis')
      })}
      validateOnBlur={false}
      validateOnChange
      onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
        try {
          const captchaResponse = await executeRecaptcha('contact');
          const formValues = {
            ...values,
            _wpcf7_recaptcha_response: captchaResponse
          };
          const formData = new FormData();
          for (const key of Object.keys(formValues)) {
            formData.append(key, formValues[key]);
          }
          const res = await axios({
            method: 'post',
            url: '/api/wp-json/contact-form-7/v1/contact-forms/99/feedback',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          });
          resetForm({
            email: '',
            firstname: '',
            surname: '',
            phone: '',
            org: '',
            message: '',
            subject: 'Question sur le sous-programme Culture'
          });
          setStatus({ success: true });
          setFormResponse(res.data.message);
          setSubmitting(false);
        } catch (e) {
          console.error(e);
        }
      }}
      render={({ isSubmitting }) => (
        <Form className="contactForm">
          <Row>
            <Col md="6">
              <Field
                type="text"
                name="firstname"
                id="firstname"
                label="Prénom *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="text"
                name="surname"
                id="surname"
                label="Nom *"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="text"
                name="org"
                id="org"
                label="Organisme"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="email"
                name="email"
                id="email"
                label="Email *"
                autoComplete="off"
                component={FormikInput}
              />
            </Col>
            <Col md="6">
              <Field
                type="text"
                name="phone"
                id="phone"
                label="Téléphone"
                autoComplete="off"
                component={FormikInput}
              />
              <Field
                type="text"
                name="subject"
                id="subject"
                label="Objet *"
                autoComplete="off"
                inputprops={{
                  options: [
                    'Question sur le sous-programme Culture',
                    'Question sur le sous-programme MEDIA',
                    'Prise de rendez-vous',
                    'Autre'
                  ]
                }}
                component={FormikSelect}
              />
              <Field
                type="textarea"
                name="message"
                rows="6"
                id="message"
                label="Message *"
                autoComplete="off"
                component={FormikInput}
              />
              <Button
                className="contactForm__submit contactPage__submit w-100 p5"
                color="success"
                type="submit"
                disabled={isSubmitting}
              >
                Envoyer
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  ) : (
    <Row className="align-middle">
      <Col xs="12">
        <p className="text-center">{formResponse}</p>
        <Button onClick={() => setFormResponse('')} color="link" className="d-block mx-auto">
          Fermer
        </Button>
      </Col>
    </Row>
  );
};

const Contact = ({ data }) => (
  <Layout>
    <Container className="my-5">
      <Row>
        <Col md="5">
          <div className="Header__text pr-5 pl-3 pt-3">
            <h1>Contactez-nous </h1>
            <Row>
              <Media src={homeIcon} height="16px" width="16px" className="m-2" />
              <p className="contactInfo">
                <span>3 ÉTAGE, THÉATRE DE L’OPÉRA - CITÉ DE LA CULTURE</span>
                <span>Avenue Mohamed V,</span>
                <span>1001 Tunis</span>
              </p>
            </Row>
            <Row>
              <Media src={mailIcon} height="16px" width="16px" className="m-2" />
              <p className="contactInfo">
                <span>CED.MEDIA@CREATIVEEUROPE.TN</span>
                <span>CED.CULTURE@CREATIVEEUROPE.TN</span>
              </p>
            </Row>
          </div>
        </Col>
        <Col md="7" className="p-3 p-md-1">
          <Img fluid={data.file.childImageSharp.fluid} className="img-fluid mx-auto d-block" />
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col lg="9" md="12">
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Layout>
);
export default Contact;
export const query = graphql`
  query {
    file(relativePath: { eq: "contact.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
